import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

const PolicyLayout = styled.main`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 30px;
  h1 {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: -0.03rem;
    line-height: 1.6;
    text-align: left;
    margin: 2rem auto;

    @media (max-width: 1366px) {
      font-size: 1.2rem;
    }
  }

  h2 {
    font-weight: 650;
    font-size: 1.3rem;
    letter-spacing: -0.03rem;

    @media (max-width: 1366px) {
      font-size: 1.1rem;
    }
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export default function BlogPostTemplate({
                                           data // this prop will be injected by the GraphQL query below.
                                         }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <PolicyLayout>
      <div>
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </PolicyLayout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;